<template>
  <section>
    <el-drawer title="数据指标介绍" :visible.sync="drawer" :direction="direction" :before-close="handleClose" size="350px" :modal="false">
      <div class="rd-body-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item v-for="item in targetDescribes" :key="item.path" :name="item.path">
            <template slot="title">
              <span :class="{active: activeName === item.path}">{{item.title}}</span>
            </template>
            <div v-for="(c, i) in item.content" :key="i" style="margin-bottom: 5px">
              <span style="font-weight: bold;">{{c.target}}：</span>
              <span class="describe">{{c.describe}}</span>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-drawer>
    <div class="rd-btn-light" @click="drawer=true">
      <font-awesome-icon icon="lightbulb" />
    </div>
  </section>
</template>

<script>
import help from '@/common/help'

export default {
  name: 'index',
  data () {
    return {
      drawer: false,
      direction: 'rtl',
      activeName: '/other',
      targetDescribes: help.targetDescribes
    }
  },
  watch: {
    '$route': function (val) {
      let path = '/other'
      for (let item of help.targetDescribes) {
        if (item.path === val.path) {
          path = val.path
        }
      }
      this.activeName = path
    }
  },
  methods: {
    handleClose (done) {
      done()
    }
  }
}
</script>

<style scoped>
  .rd-body-box {
    padding: 0 5px;
  }
  .rd-btn-light {
    position: fixed;
    right: 0;
    top: 80px;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 28px;
    border-radius: 6px 0 0 6px!important;
    pointer-events: auto;
    cursor: pointer;
    color: #fff;
    background-color: lightskyblue;
  }
  .active {
    color: #4C9FE4;
  }
</style>
