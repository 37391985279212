const targetDescribes = [
  {
    path: '/other',
    title: '基础指标',
    content: [
      { target: '新增用户', describe: '[每日]注册并登录游戏的人数' },
      { target: '活跃用户', describe: '[每日]登录过游戏的人数' },
      { target: '付费用户', describe: '[每日]付费的人数' },
      { target: '付费金额', describe: '[每日]充值金额总计' },
      { target: '新增付费用户', describe: '新增用户并付费的人数' },
      { target: '新增付费金额', describe: '新增付费用户的充值金额总计' },
      { target: '付费率', describe: '付费率 = 付费用户 ÷ 活跃用户' },
      { target: 'ARPU', describe: 'ARPU = 付费金额 ÷ 活跃用户数' },
      { target: 'ARPPU', describe: 'ARPPU = 付费金额 ÷ 付费用户数' },
      { target: '用户留存', describe: '新登用户在随后不同时期的登录使用情况' },
      { target: '用户价值', describe: '用户在生命周期内为该游戏创造的收入总计' }
    ]
  },
  {
    path: '/player/new',
    title: '新增玩家',
    content: [
      { target: '新增用户', describe: '当日新增加的玩家帐户数' },
      { target: '新增设备', describe: '当日新增加的激活设备量' },
      { target: '玩家转化', describe: '安装游戏的玩家中（即激活设备）有注册账户的玩家比例，1人多次注册，只记1次有效转化' },
      { target: '新增用户-渠道分布', describe: '新增玩家帐号的渠道分布情况，每个玩家始终归入首次设备激活时的渠道。' },
      { target: '新增用户-等级分布', describe: '玩家新增日达到的最大等级' }
    ]
  },
  {
    path: '/player/active',
    title: '活跃玩家',
    content: [
      { target: 'DAU', describe: '当日有开启过游戏的玩家数，其中当日新增玩家带来的活跃以区别的颜色显示' },
      { target: 'WAU', describe: '当日的最近一周（含当日的倒推7日）活跃玩家，将进行过游戏的玩家按照帐户进行排重' },
      { target: 'MAU', describe: '当日的最近一月（含当日的倒推30日）活跃玩家，将进行过游戏的玩家按照帐户进行排重' },
      { target: '活跃用户-渠道分布', describe: '所选时期排重活跃玩家的渠道分布情况，渠道按照其最初新增时的渠道确定' },
      { target: '活跃用户-等级分布', describe: '所选时期每日活跃玩家的等级分布情况，由每日数据组成的时期趋势可观测等级的总体走势' }
    ]
  },
  {
    path: '/player/keep',
    title: '玩家留存',
    content: [
      { target: '用户留存', describe: '用户的次日、3日、7日、14日、30日、60日、90日留存率' },
      { target: '用户留存-渠道分布', describe: '圈定时期内符合留存条件的新玩家，根据其渠道来进行分布' },
      { target: '新增日留存', describe: '圈定时期内符合留存条件的新玩家，根据其新增日的使用行为（等级、游戏次数、是否付费等）来进行分布，可有效的分析新增日行为与留存的相关性' },
      { target: '周期内留存', describe: '圈定时期内符合留存条件的新玩家，根据其周期内的使用行为（等级、游戏次数、是否付费）来进行分布，可有效的分析新增日行为与留存的相关性' }
    ]
  },
  {
    path: '/player/device',
    title: '设备相关',
    content: [
      { target: '机型', describe: '所选玩家的机型分布情况' },
      { target: '分辨率', describe: '所选玩家的分辨率分布情况' },
      { target: '操作系统', describe: '所选玩家的设备操作系统分布情况' }
    ]
  },
  {
    path: '/player/pay/conversion',
    title: '付费转化',
    content: [
      { target: '新增付费用户', describe: '当日新增加的充值玩家' },
      { target: '累计付费用户', describe: '截至当日，累计的充过值的玩家数。' },
      { target: '总体付费率', describe: '截至当日，累计付费玩家占累计总玩家的比例（累计付费玩家/累计玩家帐户）' },
      { target: '首日付费率', describe: '所选时期的新增玩家中，在新增当日即进行充值的玩家数和其占比' },
      { target: '首周付费率', describe: '所选时期的新增玩家中，在新增后的7日内进行了充值的玩家数和其占比' },
      { target: '首月付费率', describe: '所选时期的新增玩家中，在新增后的30日内进行了充值的玩家数和其占比' },
      { target: '首充等级', describe: '所选时期内，玩家首次付费时的等级分布情况' },
      { target: '首充商品', describe: '玩家在游戏中首次充值时，所选择的充值包的类型分布情况' },
      { target: '首充金额', describe: '玩家在游戏中首次充值时，支付的单笔金额的分布情况' },
      { target: '首充周期', describe: '所选时期内的新增付费玩家，按照其已经玩游戏的天数（首付日期-新增日期）进行分布' }
    ]
  },
  {
    path: '/player/level',
    title: '等级分布',
    content: [
      { target: '活跃用户-等级分布', describe: '所选期间中排重活跃玩家的等级分布情况，按每玩家在期间中最终等级计算等级。可切换时间来单日查询等级分布，通过连续几日的对比来观测活跃玩家等级变迁情况' },
      { target: '游戏次数-等级分布', describe: '所选期间中各等级中玩家游戏次数情况' }
    ]
  },
  {
    path: '/map/region',
    title: '地理位置',
    content: [
      { target: '活跃用户', describe: '所选时期的活跃用户分布图' },
      { target: '新增用户', describe: '所选时期的新增用户分布图' },
      { target: '付费金额', describe: '所选时期的付费金额分布图' },
      { target: '付费用户', describe: '所选时期的付费用户分布图' }
    ]
  },
  {
    path: '/process',
    title: '流程统计',
    content: [
      { target: '流失率', describe: '(用户数 - 角色账号)/ 用户数' },
      { target: '验证流失率', describe: '(用户数 - 验证数)/ 用户数' }
    ]
  },
  {
    path: '/pay/money',
    title: '收入数据',
    content: [
      { target: '付费金额', describe: '所选时期每日玩家充值的货币总额' },
      { target: '付费次数', describe: '所选时期每日玩家进行充值的总次数' },
      { target: '付费用户', describe: '当日进行充值的玩家帐户数。充值人数是所选时期充值玩家的去重数目，不是简单加和。' },
      { target: '付费金额-等级分布', describe: '所选时期的充值总金额根据玩家充值时的等级进行分布' },
      { target: '付费人次-等级分布', describe: '所选时期每次充值根据玩家充值时的等级进行分布' },
      { target: '付费金额-渠道分布', describe: '所选期间，各个渠道来源的玩家充值金额的分布情况' }
    ]
  },
  {
    path: '/pay/permeate',
    title: '付费渗透',
    content: [
      { target: '日付费率', describe: '当日付费用户 / 当日活跃用户' },
      // { target: '周付费率', describe: '未知' },
      // { target: '月付费率', describe: '未知' },
      { target: '日ARPU', describe: ' 当日每活跃玩家平均收入（收入/活跃玩家）' },
      { target: '月ARPU', describe: ' 某自然月每活跃玩家平均收入（收入/月排重活跃玩家）' },
      { target: '日ARPPU', describe: ' 当日每付费玩家平均收入（收入/付费玩家数）' },
      { target: '月ARPPU', describe: ' 某自然月每付费玩家平均收入（收入/月排重付费玩家数）' }
    ]
  },
  {
    path: '/pay/ltv',
    title: '用户价值',
    content: [
      { target: '新玩家价值', describe: '某日新增玩家（账户）中，平均每玩家在接下来的N日内（新增日和之后的N日）为游戏内带来的收入' },
      { target: '新玩家价值-渠道分布', describe: '某渠道内新增玩家（账户）中，平均每玩家在接下来的N日内（新增日和之后的N日）为游戏内带来的收入' }
    ]
  }
]
export default {
  targetDescribes
}
