import Vue from 'vue'
import util from './util'

// 解决数据大，加载较慢

// 为element的Select添加无限下拉指令
Vue.directive('el-select-loadmore', function (el, binding) {
  // 这里将会被 `bind` 和 `update` 调用
  // 获取element-ui定义好的scroll盒子
  const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
  if (SELECTWRAP_DOM) {
    SELECTWRAP_DOM.addEventListener('scroll', util.debounce(function () {
      /**
       * scrollHeight 获取元素内容高度(只读)
       * scrollTop 获取或者设置元素的偏移值,
       *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
       * clientHeight 读取元素的可见高度(只读)
       * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
       * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
       */
      const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
      if (condition) binding.value()
    }))
  }
})
// 为element的table添加X轴无限下拉指令
Vue.directive('el-table-loadmore', {
  update: function (el, binding) {
    const scroll = {
      x: 0,
      y: 0
    }
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector('.el-tabs__content .el-table__body-wrapper')
    if (SELECTWRAP_DOM) {
      SELECTWRAP_DOM.addEventListener('scroll', util.debounce(function () {
        if (scroll.y !== this.scrollTop && this.scrollTop > scroll.y && this.scrollHeight - this.scrollTop <= this.clientHeight) {
          // console.log(scroll.y, this.scrollTop, '这里是不等于 scroll.y !== this.scrollTop')
          binding.value('y')
          scroll.y = this.scrollTop
        }
        if (scroll.x !== this.scrollLeft && this.scrollLeft > scroll.x && this.scrollWidth - this.scrollLeft <= this.clientWidth) {
          binding.value('x')
          scroll.x = this.scrollLeft
        }
      }))
    } else {
      binding.value(false)
    }
  }
})
