import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Moment from 'vue-moment'
import ElementUI from 'element-ui'
import Echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css'
// import 'element-ui/lib/theme-chalk/base.css'
import './common/permission'
import './filter'
import './common/directives' // 自定义指令
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueParticles from 'vue-particles'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

// library.add(fas, far, fab)
library.add(fas)
dom.watch()

Vue.component('font-awesome-icon', FontAwesomeIcon)

// 开发环境不需要报错监控
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'http://73520866779dd2eab2f887d5cda2cc83@sentry.begindcc.com/8',
    integrations: [ new Integrations.Vue({ Vue, attachProps: true }) ]
  })
}

Vue.config.productionTip = false
Vue.prototype.$echarts = Echarts

Vue.use(Moment)
Vue.use(ElementUI)
Vue.use(VueParticles)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
