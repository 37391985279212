import moment from 'moment'
const CHARSETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

const randomText = len => {
  return Array.apply(0, new Array(len)).map(function () {
    return (function (charset) {
      return charset.charAt(Math.floor(Math.random() * charset.length))
    }(CHARSETS))
  }).join('')
}

const convertToChartData = (targetsData, dataKeyName, dataValueName) => {
  if (!targetsData) {
    return {}
  }
  let tdKey = dataKeyName || 'date'
  let tdValue = dataValueName || 'value'
  let ds = new Set()
  let tmp = {}
  for (let target in targetsData) {
    if (!targetsData.hasOwnProperty(target)) {
      continue
    }
    let td = targetsData[target]
    tmp[target] = new Map()
    for (let d of td) {
      if (!d.hasOwnProperty(tdKey)) {
        continue
      }
      tmp[target].set(d[tdKey], d[tdValue])
      ds.add(d[tdKey])
    }
  }
  let keys = [...ds].sort(keyCompareFn)
  let data = {}
  data[tdKey] = keys
  for (let target in targetsData) {
    if (!targetsData.hasOwnProperty(target)) {
      continue
    }
    let td = []
    for (let k of keys) {
      let val = tmp[target].get(k) || 0
      td.push(val)
    }
    data[target] = td
  }
  return data
}

const keyCompareFn = (a, b) => {
  let isNumber = !isNaN(a) && !isNaN(b)
  if (isNumber) {
    return a - b
  }
  let au = a.toUpperCase()
  let bu = b.toUpperCase()
  if (au < bu) {
    return -1
  }
  if (au > bu) {
    return 1
  }
  return 0
}

const convertToChartDataByTargets = (targets, targetsData, dataKeyName, dataValueName) => {
  if (!targets || !targetsData) {
    return {}
  }
  let len = targets.length
  if (len === 1) {
    return convertToChartDataOne(targets[0].value, targetsData, dataKeyName, dataValueName)
  }
  let targetSet = new Set()
  for (let t of targets) {
    targetSet.add(t.value)
  }
  let dataTargetSet = new Set()
  let target = ''
  for (let key in targetsData) {
    if (!targetsData.hasOwnProperty(key)) {
      continue
    }
    if (targetSet.has(key)) {
      target = key
      dataTargetSet.add(key)
    }
  }
  if (dataTargetSet.size === 1) {
    return convertToChartDataOne(target, targetsData, dataKeyName, dataValueName, targetSet)
  }
  return convertToChartData(targetsData, dataKeyName, dataValueName)
}

const convertToChartDataOne = (target, targetsData, dataKeyName, dataValueName, targetSet) => {
  if (!targetsData) {
    return {}
  }
  let td = targetsData[target]
  if (!td) {
    return {}
  }
  let tdKey = dataKeyName || 'date'
  let tdValue = dataValueName || 'value'
  let data = {}
  data[tdKey] = []
  data[target] = []
  if (targetSet) {
    for (let t of targetSet.keys()) {
      if (t === target) {
        continue
      }
      data[t] = []
    }
  }
  for (let d of td) {
    data[tdKey].push(d[tdKey])
    data[target].push(d[tdValue])
    if (targetSet) {
      for (let t of targetSet.keys()) {
        if (t === target) {
          continue
        }
        if (!d.hasOwnProperty(t)) {
          continue
        }
        data[t].push(d[t])
      }
    }
  }
  return data
}

const tdToMathFunc = (td1, td2, func, dataKeyName, dataValueName, isPercent) => {
  let tdKey = dataKeyName || 'date'
  let tdValue = dataValueName || 'value'
  let ds = new Set()
  let m1 = new Map()
  let m2 = new Map()
  for (let d of td1) {
    m1.set(d[tdKey], d[tdValue])
    ds.add(d[tdKey])
  }
  for (let d of td2) {
    m2.set(d[tdKey], d[tdValue])
    ds.add(d[tdKey])
  }
  let keys = [...ds].sort(keyCompareFn)
  let ret = []
  for (let k of keys) {
    let a = m1.get(k) || 0
    let b = m2.get(k) || 0
    let val = func(a, b, isPercent)
    let obj = {}
    obj[tdKey] = k
    obj[tdValue] = val
    ret.push(obj)
  }
  return ret
}

const MathFuncSubtract = (a, b) => {
  return a - b
}

const MathFuncDivision = (a, b, isPercent) => {
  if (b === 0) {
    return 0
  }
  let aa = isPercent ? a * 100 : a
  return (aa / b).toFixed(2) * 1
}

const tdToMathSubtract = (td1, td2, dataKeyName, dataValueName) => {
  return tdToMathFunc(td1, td2, MathFuncSubtract, dataKeyName, dataValueName)
}

const tdToMathDivisionPoint = (td1, td2, isPercent, dataKeyName, dataValueName) => {
  return tdToMathFunc(td1, td2, MathFuncDivision, dataKeyName, dataValueName, isPercent)
}

const getDateRangeValue = days => {
  let startTime = moment().add(days, 'days').format('YYYY-MM-DD')
  let endTime = moment().add(days === -1 ? -1 : 0, 'days').format('YYYY-MM-DD')
  return [startTime, endTime]
}
// 防抖
function debounce (func, delay = 300) {
  let timeout
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(this, arguments)
    }, delay)
  }
}
// 节流
function throttle (fn, delay = 300) {
  let valid = true
  return function () {
    if (!valid) {
      // 休息时间 暂不接客
      return false
    }
    // 工作时间，执行函数并且在间隔期内把状态位设为无效
    valid = false
    setTimeout(() => {
      fn.apply(this, arguments)
      valid = true
    }, delay)
  }
}

function hasUrlPrefix (url) {
  return /^(http|https):\/\//.test(url)
}

export default {
  randomText,
  convertToChartDataByTargets,
  convertToChartData,
  convertToChartDataOne,
  tdToMathSubtract,
  tdToMathDivisionPoint,
  getDateRangeValue,
  debounce,
  throttle,
  hasUrlPrefix
}
