import api from '@/api/login'
import store from '@/store/store'
import { Message } from 'element-ui'

const user = {
  state: {
    token: store.getToken(),
    user: store.getUser()
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    }
  },
  actions: {
    // 第三方验证登录
    ThirdLogin ({ commit, state }, accessToken) {
      return new Promise((resolve, reject) => {
        store.setToken(accessToken)
        commit('SET_TOKEN', accessToken)
        resolve()
      })
    },
    // 获取用户信息
    GetInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        api.getInfo().then(res => {
          const { code, msg, data } = res
          if (code === 200) {
            commit('SET_USER', data)
            store.setUser(data)
          } else {
            Message.error(`${code}: ${msg}`)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        api.logout().then(() => {
          // resolve()
        })
        //   .catch(error => {
        //   reject(error)
        // })
        store.removeToken()
        store.removeUser()
        store.removeAppId()
        resolve()
      })
    },
    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        store.removeToken()
        store.removeUser()
        store.removeAppId()
        resolve()
      })
    }
  }
}

export default user
