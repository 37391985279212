import http from './http'

export default {
  gameList () {
    return http.get('/api/channel/auth-game')
  },
  channelList (appId) {
    return http.get(`/api/channel/auth-platform-channel`, { params: { appId: appId } })
  },
  gameProducts (appId) {
    return http.get(`/api/c/product`, { params: { appId: appId } })
  },
  serverList (appId) {
    return http.get(`/bi/app/${appId}/server`)
  }
  // getChannelList (appId) {
  //   return http.get(`/channel/api/app/${appId}/channel`)
  // },
  // // 渠道分组
  // getChannelGroup (appId) {
  //   return http.get(`/bi/${appId}/channels/group`)
  // }
}
