import http from './http'
import p from '../common/privilege'

export default {
  getInfo () {
    return http.get('/bi/auth/user')
  },
  logout () {
    return http.get(p.OAuthExitUrl)
  }
}
