<template>
  <div class="breadcrumb">
    <el-breadcrumb separator="/">
      <template v-for="(item, i) in data">
        <el-breadcrumb-item v-if="item.meta && item.meta.breadcrumbLinkDisable" :key="i">{{item.name}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else :key="i" :to="{path: item.path}">{{item.name}}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data () {
    return {
      data: []
    }
  },
  created () {
    this.initBreadcrumbMenus()
  },
  watch: {
    $route () {
      this.initBreadcrumbMenus()
    }
  },
  methods: {
    initBreadcrumbMenus () {
      let menus = this.$route.matched.filter(item => !item.hidden && item.name)
      let isHome = this.isHome(menus[0])
      if (!isHome) {
        menus = [{ path: '/home', name: '首页' }].concat(menus)
      }
      this.data = menus
    },
    isHome (route) {
      if (!route || !route.name) {
        return false
      }
      let path = route.path
      return path && path.trim().toLocaleLowerCase() === '/home'.toLocaleLowerCase()
    }
  }
}
</script>

<style scoped>
.breadcrumb {
  display: flex;
  align-items: center;
}
</style>
