const objectToOptions = obj => {
  let options = []
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue
    }
    options.push(obj[key])
  }
  return options
}

const getTargetName = key => {
  let t = targetType[key]
  if (!t) {
    return key
  }
  return t.label
}

const charsOptions = [
  { value: 'bar', label: '柱形图' },
  { value: 'line', label: '曲线图' },
  { value: 'heapBar', label: '堆叠柱状图' }
]

const targetType = {
  loginUser: { value: 'loginUser', label: '活跃用户' },
  loginUserAdd: { value: 'loginUserAdd', label: '新增用户' },
  loginDevice: { value: 'loginDevice', label: '活跃设备' },
  loginDeviceAdd: { value: 'loginDeviceAdd', label: '新增设备' },
  roll: { value: 'roll', label: '滚服-人数' },
  origin: { value: 'origin', label: '非滚服-人数' },
  payUser: { value: 'payUser', label: '付费用户' },
  payUserAdd: { value: 'payUserAdd', label: '新增付费用户' },
  payMoney: { value: 'payMoney', label: '付费金额' },
  payMoneyAdd: { value: 'payMoneyAdd', label: '新增付费金额' },
  payUserTotal: { value: 'payUserTotal', label: '累计付费用户' },
  loginUserTotal: { value: 'loginUserTotal', label: '累计登录用户' },
  loginUserAddDevice: { value: 'loginUserAddDevice', label: '新增用户设备' },
  weekLoginUser: { value: 'loginUser7d', label: '周活跃用户' },
  monthLoginUser: { value: 'loginUser30d', label: '月活跃用户' },
  payUserAdd1d: { value: 'payUserAdd', label: '首日付费用户' },
  payUserAdd7d: { value: 'payUserAdd7d', label: '首周付费用户' },
  payUserAdd30d: { value: 'payUserAdd30d', label: '首月付费用户' },
  verify: { value: 'verify', label: '验证数' },
  verifyPassed: { value: 'verifyPassed', label: '通过数' },
  roleUser: { value: 'roleUser', label: '角色账号' },
  user: { value: 'user', label: '用户数' },
  role: { value: 'role', label: '角色数' },
  loginRoleAdd: { value: 'loginRoleAdd', label: '新增角色数' },
  loginRoleOld: { value: 'loginRoleOld', label: '老角色数' },
  loginRole: { value: 'loginRole', label: '活跃角色数' },
  // 以下指标不存在，仅前端使用
  ARPPU: { value: 'arppu', label: 'ARPPU' },
  ARPU: { value: 'arpu', label: 'ARPU' },
  arppu: { value: 'arppu', label: 'ARPPU' },
  arpu: { value: 'arpu', label: 'ARPU' },
  arpuAdd: { value: 'arpuAdd', label: '新增ARPU' },
  k: { value: 'keep', label: '留存' },
  l: { value: 'ltv', label: 'LTV' },
  payCount: { value: 'payCount', label: '付费次数' },
  loginCount: { value: 'loginCount', label: '游戏次数' },
  payRate: { value: 'payRate', label: '付费率' },
  payRateAdd: { value: 'payRateAdd', label: '新增付费率' },
  payRate1d: { value: 'payRate1d', label: '首日付费率（%）' },
  payRate7d: { value: 'payRate7d', label: '首周付费率（%）' },
  payRate30d: { value: 'payRate30d', label: '首月付费率（%）' },
  firstPay: { value: 'firstPay', label: '首充' },
  firstPayLevel: { value: 'firstPayLevel', label: '人数' },
  firstPayProduct: { value: 'firstPayProduct', label: '人数' },
  firstPayMoney: { value: 'firstPayMoney', label: '人数' },
  firstPayDays: { value: 'firstPayDays', label: '人数' },
  loginUserOld: { value: 'loginUserOld', label: '老用户' },
  payUserOld: { value: 'payUserOld', label: '老付费用户' },
  payMoneyOld: { value: 'payMoneyOld', label: '老付费金额' },
  payRateTotal: { value: 'payRateTotal', label: '总体付费率（%）' },
  loginUserAddRate: { value: 'loginUserAddRate', label: '新用户占比' },
  loginRoleAddRate: { value: 'loginRoleAddRate', label: '新角色占比' },
  payMoneyAddRate: { value: 'payMoneyAddRate', label: '新付费金额占比' },
  payUserAddRate: { value: 'payUserAddRate', label: '新付费用户占比' },
  loginUserAddConversion: { value: 'loginUserAddConversion', label: '转化率（%）' },
  conversion: { value: 'conversion', label: '玩家转化率（%）' },
  churnRate: { value: 'churnRate', label: '流程流失率（%）' },
  verifyChurnRate: { value: 'verifyChurnRate', label: '验证流失率（%）' }
}

const keepType = {
  loginUserAdd: { value: 'loginUserAdd', label: '当日' },
  k2: { value: 'k2', label: '次日' },
  k3: { value: 'k3', label: '3日' },
  k4: { value: 'k4', label: '4日' },
  k5: { value: 'k5', label: '5日' },
  k6: { value: 'k6', label: '6日' },
  k7: { value: 'k7', label: '7日' },
  k14: { value: 'k14', label: '14日' },
  k30: { value: 'k30', label: '30日' },
  k45: { value: 'k45', label: '45日' },
  k60: { value: 'k60', label: '60日' },
  k90: { value: 'k90', label: '90日' },
  k120: { value: 'k120', label: '120日' },
  k180: { value: 'k180', label: '180日' },
  k180later: { value: 'k180later', label: '180日+' }
}

const keepRateType = {
  loginUserAdd: { value: 'loginUserAdd', label: '当日' },
  keep2: { value: 'keep2', label: '次日' },
  keep3: { value: 'keep3', label: '3日' },
  keep4: { value: 'keep4', label: '4日' },
  keep5: { value: 'keep5', label: '5日' },
  keep6: { value: 'keep6', label: '6日' },
  keep7: { value: 'keep7', label: '7日' },
  keep14: { value: 'keep14', label: '14日' },
  keep30: { value: 'keep30', label: '30日' },
  keep45: { value: 'keep45', label: '45日' },
  keep60: { value: 'keep60', label: '60日' },
  keep90: { value: 'keep90', label: '90日' },
  keep120: { value: 'keep120', label: '120日' },
  keep180: { value: 'keep180', label: '180日' },
  keep180later: { value: 'keep180later', label: '180日+' }
}

const ltvType = {
  loginUserAdd: { value: 'loginUserAdd', label: '当日' },
  l2: { value: 'l2', label: '次日' },
  l3: { value: 'l3', label: '3日' },
  l4: { value: 'l4', label: '4日' },
  l5: { value: 'l5', label: '5日' },
  l6: { value: 'l6', label: '6日' },
  l7: { value: 'l7', label: '7日' },
  l14: { value: 'l14', label: '14日' },
  l30: { value: 'l30', label: '30日' },
  l45: { value: 'l45', label: '45日' },
  l60: { value: 'l60', label: '60日' },
  l90: { value: 'l90', label: '90日' },
  l120: { value: 'l120', label: '120日' },
  l180: { value: 'l180', label: '180日' },
  l180later: { value: 'l180later', label: '180日+' }
}

const ltvRateType = {
  loginUserAdd: { value: 'loginUserAdd', label: '当日' },
  ltv2: { value: 'ltv2', label: '次日' },
  ltv3: { value: 'ltv3', label: '3日' },
  ltv4: { value: 'ltv4', label: '4日' },
  ltv5: { value: 'ltv5', label: '5日' },
  ltv6: { value: 'ltv6', label: '6日' },
  ltv7: { value: 'ltv7', label: '7日' },
  ltv14: { value: 'ltv14', label: '14日' },
  ltv30: { value: 'ltv30', label: '30日' },
  ltv45: { value: 'ltv45', label: '45日' },
  ltv60: { value: 'ltv60', label: '60日' },
  ltv90: { value: 'ltv90', label: '90日' },
  ltv120: { value: 'ltv120', label: '120日' },
  ltv180: { value: 'ltv180', label: '180日' },
  ltv180later: { value: 'ltv180later', label: '180日+' }
}

const payRangeType = {
  1: { value: '1', label: '[0,10)' },
  2: { value: '2', label: '[10,50)' },
  3: { value: '3', label: '[50,100)' },
  4: { value: '4', label: '[100,200)' },
  5: { value: '5', label: '[200,500)' },
  6: { value: '6', label: '[500,1k)' },
  7: { value: '7', label: '[1k,5k)' },
  8: { value: '8', label: '[5k,+)' }
}

const nameMap = {
  'Guangdong': '广东',
  'Jiangsu': '江苏',
  'Henan': '河南',
  'Shandong': '山东',
  'Beijing': '北京',
  'Zhejiang': '浙江',
  'Hebei': '河北',
  'Sichuan': '四川',
  'Anhui': '安徽',
  'Liaoning': '辽宁',
  'Shaanxi': '陕西',
  'Hunan': '湖南',
  'Hubei': '湖北',
  'Fujian': '福建',
  'Guangxi': '广西',
  'Shanghai': '上海',
  'Yunnan': '云南',
  'Shanxi': '山西',
  'Heilongjiang': '黑龙江',
  'Chongqing': '重庆',
  'Jiangxi': '江西',
  'Jilin': '吉林',
  'Guizhou': '贵州',
  'Tianjin': '天津',
  'Gansu': '甘肃',
  'Xinjiang': '新疆',
  'Hainan': '海南',
  'Qinghai': '青海',
  'Ningxia Hui Autonomous Region': '宁夏',
  'Inner Mongolia Autonomous Region': '内蒙古',
  'Tibet': '西藏',
  'Taipei City': '台湾'
}

const orderStatusOptions = [
  { value: -1, label: '-1-失败' },
  { value: 0, label: '0-未支付' },
  { value: 1, label: '1-已支付' },
  { value: 2, label: '2-已完成' }
]

const loginCountOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2-3' },
  { value: '3', label: '4-5' },
  { value: '4', label: '6-10' },
  { value: '5', label: '11-20' },
  { value: '6', label: '21-50' },
  { value: '7', label: '50+' }
]

const payCountOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6-10' },
  { value: '7', label: '11-20' },
  { value: '8', label: '21-30' },
  { value: '9', label: '31-40' },
  { value: '10', label: '41-50' },
  { value: '11', label: '50+' }
]

const payRangeOptions = [
  { value: '1', label: '[0,10)' },
  { value: '2', label: '[10,50)' },
  { value: '3', label: '[50,100)' },
  { value: '4', label: '[100,200)' },
  { value: '5', label: '[200,500)' },
  { value: '6', label: '[500,1k)' },
  { value: '7', label: '[1k,5k)' },
  { value: '8', label: '[5k,+)' }
]

const intervalOptions = [
  { value: '1', label: '首日' },
  { value: '2', label: '2-3天' },
  { value: '3', label: '4-7天' },
  { value: '4', label: '2周' },
  { value: '5', label: '3周' },
  { value: '6', label: '4周' },
  { value: '7', label: '5周' },
  { value: '8', label: '6周' },
  { value: '9', label: '7周' },
  { value: '10', label: '8周' },
  { value: '11', label: '9-12周' },
  { value: '12', label: '13周+' }
]

const currency2CNYRate = {
  CNY: 1,
  RMB: 1,
  USD: 7,
  TWD: 0.2
}

let contrastOptions = new Map()

// 状态 1:正常，2:停用
const organizeStatusOptions = [
  { value: 1, label: '1-正常' },
  { value: 2, label: '2-停用' }
]

// 渲染类型 1：TEXT，2：HTML, 3: JSON, 4: JSONP, 9: URL
const organizeRenderTypeOptions = [
  { value: 1, label: '1-TEXT' },
  { value: 2, label: '2-HTML' },
  { value: 9, label: '9-URL' }
  // { value: 3, label: 'JSON' },
  // { value: 4, label: 'JSONP' }
]

export default {
  charsOptions,
  targetType,
  targetOptions: objectToOptions(targetType),
  keepType,
  keepRateType,
  keepOptions: objectToOptions(keepType),
  ltvType,
  ltvRateType,
  ltvOptions: objectToOptions(ltvType),
  getTargetName,
  nameMap,
  orderStatusOptions,
  payRangeOptions,
  loginCountOptions,
  payCountOptions,
  intervalOptions,
  payRangeType,
  currency2CNYRate,
  contrastOptions,
  organizeStatusOptions,
  organizeRenderTypeOptions
}
