const getters = {
  token: state => state.user.token,
  user: state => state.user.user,
  // avatar: state => state.user.avatar,
  name: state => state.user.user.name,
  resValues: state => state.user.user.resValues || [],
  device: state => state.app.device,
  isMobile: state => state.app.device === 'mobile',
  sidebar: state => state.app.sidebar,
  games: state => state.app.games,
  appId: state => state.app.appId,
  products: state => state.app.products,
  channels: state => state.app.channels,
  channelId: state => state.app.channelId,
  channelCascadeOptions: state => state.app.channelCascadeOptions,
  channelCascadeIds: state => state.app.channelCascadeIds,
  dataPickerValue: state => state.app.dataPickerValue,
  servers: state => state.app.servers,
  serverList: state => state.app.serverList,
  serverId: state => state.app.serverId,
  routerPrivilege: state => state.app.routerPrivilege
}
export default getters
